exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-faq-index-js": () => import("./../../../src/pages/faq/index.js" /* webpackChunkName: "component---src-pages-faq-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-platform-index-js": () => import("./../../../src/pages/platform/index.js" /* webpackChunkName: "component---src-pages-platform-index-js" */),
  "component---src-pages-press-index-js": () => import("./../../../src/pages/press/index.js" /* webpackChunkName: "component---src-pages-press-index-js" */),
  "component---src-pages-roadmap-index-js": () => import("./../../../src/pages/roadmap/index.js" /* webpackChunkName: "component---src-pages-roadmap-index-js" */),
  "component---src-pages-team-index-js": () => import("./../../../src/pages/team/index.js" /* webpackChunkName: "component---src-pages-team-index-js" */),
  "component---src-pages-use-cases-index-js": () => import("./../../../src/pages/use-cases/index.js" /* webpackChunkName: "component---src-pages-use-cases-index-js" */)
}

